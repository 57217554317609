import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from '../../../components/Layout/Layout'
import Therapist from "../../../components/Therapist/Therapist"

// markup
const name = 'Jennifer Gleason-Wilson'
const license = 'MA, LPC'
const areas = [
    'Anxiety',
    'Depression',
    'ADD/ADHD',
]

const IndexPage = () =>
(
    <Layout className="">
        <Therapist
            name={name}
            license={license}
            areas={areas}
            photo={(<StaticImage src='./jennifer-gleason-wilson.jpg' alt={name} layout="constrained" className="shadow" />)}
        >

            <p>Jennifer is a therapist at Integrative Wellness.</p>
        </Therapist>
    </Layout>
)

export default IndexPage
